import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Content from '@components/Content'
import PrivateRoute from 'utils/PrivateRoute'
import { withTranslation } from 'react-i18next'

const styles = (theme) => ({
	secondaryBar: {
		zIndex: 0,
	},
})

function Legacy({ t, classes, location, pageContext }) {
	const title = t(`${pageContext.titleKey}.title`)
	return (
		<>
			<AppBar component="div" className={classes.secondaryBar} color="primary" position="static" elevation={0}>
				<Toolbar>
					<Grid container alignItems="center" spacing={2}>
						<Grid item xs>
							<Typography style={{ textTransform: 'capitalize' }} color="inherit" variant="h5">
								{title}
							</Typography>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>

			<Content
				title={"We're making a better tool for analysis"}
				subtitle={`To see the old analysis dashboard - check it out at https://app.bigdieta.com`}
				body={
					<>
						<Grid
							container
							justifyContent="center"
							alignItems="center"
							style={{
								marginTop: 10,
							}}
						>
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									const ChatWindow = window.open(
										`https://app.bigdieta.com`,
										'',
										'menubar=1,resizable=1,width=1250,height=850,top=0,left=1000'
									)
								}}
							>
								View Analysis
							</Button>
						</Grid>
					</>
				}
			/>
		</>
	)
}

Legacy.propTypes = {
	location: PropTypes.object,
}

const LegacyPage = ({ location, ...rest }) => {
	return <PrivateRoute location={location} component={Legacy} {...rest} />
}

export default compose(withStyles(styles), withTranslation())(LegacyPage)
